import Loadable from "../../../components/Loadable";

const PengaturanRapor = Loadable(() => import("./PengaturanRapor"));
const CetakSampulRapor = Loadable(() => import("./CetakSampulRapor"));
const DetilCetakRapor = Loadable(() => import("./DetilCetakRapor"));
const DetilCetakRaporHafalan = Loadable(() => import("./DetilCetakRaporHafalan"));
const InputCatatanRapor = Loadable(() => import("./InputCatatanRapor"));
const CetakRapor = Loadable(() => import("./CetakRapor"));
const CetakRaporHafalan = Loadable(() => import("./CetakRaporHafalan"));
const DetilCatatanRapor = Loadable(() => import("./DetilCatatanRapor"));
// const CetakKHS = Loadable(() => import("./CetakKHS"));
// const DetilCetakKHS = Loadable(() => import("./DetilCetakKHS"));
const DaftarRekapNSUN = Loadable(() => import("./RekapNSUN/DaftarRekapNSUN"));
const DetilRekapNSUN = Loadable(() => import("./RekapNSUN/DetilRekapNSUN"));
const DetilRekapPerMurid = Loadable(() => import("./RekapNSUN/DetilRekapPerMurid"));
const DetilDokumenKelulusan = Loadable(() => import("./DokumenKelulusan/DetilDokumenKelulusan"));
const DaftarDokumenKelulusan = Loadable(() => import("./DokumenKelulusan/DaftarDokumenKelulusan"));
const DaftarRekapRapor = Loadable(() => import('../Rapor/RekapNilaiRapor/DaftarRekapRapor'));
const DetilRekapRapor = Loadable(() => import('../Rapor/RekapNilaiRapor/DetilRekapRapor'));
const EditRekapPerUser = Loadable(() => import('../Rapor/RekapNilaiRapor/EditRekapPerUser'));

export default {
	PengaturanRapor,
	DetilCatatanRapor,
	CetakRapor,
	CetakRaporHafalan,
	CetakSampulRapor,
	InputCatatanRapor,
	DetilCetakRapor,
	DetilCetakRaporHafalan,
	DaftarDokumenKelulusan,
	DetilDokumenKelulusan,
	DetilRekapPerMurid,
	DetilRekapNSUN,
	DaftarRekapNSUN,
	DaftarRekapRapor,
	EditRekapPerUser ,
	DetilRekapRapor ,
};
